@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 10pt;
}

h4, strong {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}

h1 {
    font-size: 14pt;
    margin-top: 30px;
    font-family: 'Open Sans', sans-serif;
}

h4 {
    font-size: 10pt;
    margin-bottom: 2pt;
}

body.small {
    font-size: 8pt;
}

body.small h1 {
    font-size: 10pt;
}

body.small h4 {
    font-size: 8pt;
}

div.small {
    font-size: 8pt;
}

p, li {
    font-family: 'Open Sans', sans-serif;
    text-align: justify;
    line-height: 140%;
}

li.left {
    text-align: left
}

li {
    margin-bottom: 0.8em;
    /*list-style-position: inside;*/
}

li li {
    margin-left: -17pt;
}

ul, ol {
    margin-top: 5pt;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

div.rightFloat {
    float: right;
}

div.leftFloat {
    float: left;
}

div.clearFloat {
    clear: both;
}

div {
    margin: 0;
    padding: 0;
}

.red {
    color: red;
}

table {
    width: 100%;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

table.two-colums td {
    width: 45%;
}

td, th {
    font-size: 12px;
    text-align: left;
    padding: 10px;
    border-top: 1px solid #ddd;
}

td li {
    text-align: left;
}

table tr.title {
    font-weight: bold;
}

table.compact {
    border-collapse: collapse;
}

table.compact li, table.compact ul {
    margin-left: 8px;
    padding-left: 0;
}

table.compact, table.compact th, table.compact td {
    border: 1px solid black;
}

table.compact th, table.compact td {
    font-size: 10px;
    padding: 4px;
}

table.no-border, table.no-border td, table.no-border tr {
    border: 0;
}

.page-break {
    page-break-before: always;
}