@font-face {
    font-family: 'dancing-script-bold';
    src: url('../public/fonts/dancingscript/DancingScript_Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/roboto/Roboto_Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto-bold';
    src: url('../public/fonts/roboto/Roboto_Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    src: url('../public/fonts/poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Poste';
    src: url('../public/fonts/Poste/Poste.ttf') format('truetype');
}

[data-amplify-authenticator] .amplify-button--primary:hover {
    --amplify-internal-button-background-color: #fa7e48;
    opacity: 0.75 !important;
    box-shadow: 0 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0 -1px 0 rgba(14, 14, 44, 0.4) !important;
}

[data-amplify-authenticator] .amplify-button--primary {
    box-shadow: 0 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0 -1px 0 rgba(14, 14, 44, 0.4) !important;
}

[data-amplify-authenticator] .amplify-button--primary:active {
    background: #FA7E48 !important;
    opacity: 0.5 !important;
    box-shadow: 0 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0 -1px 0 rgba(14, 14, 44, 0.4) !important;
}

[data-amplify-authenticator] .amplify-button--link:active {
    background: #ffe7db !important;
    opacity: 0.5 !important;
    color: #FA7E48;
}

[data-amplify-authenticator] .amplify-button:active {
    background: #ffe7db !important;
}

svg {
    display: inline-block;
    vertical-align: middle;
}

.ReactCrop__drag-handle {
    border-radius: 50%;
    border-width: 0;
    background-color: white;
}

.ReactCrop__crop-selection {
    animation: none !important;
    -webkit-animation: none !important;
    background: transparent !important;
    border-width: 1px;
    border-style: solid;
    border-color: white;
}

.MuiButton-containedPrimary span {
    /* Label */

    /* Button */
    font-family: 'Roboto', sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    /* identical to box height */
    display: flex !important;
    align-items: center !important;
    letter-spacing: 0.04em !important;
    text-transform: lowercase !important;

    /* White */
    color: #FFFFFF !important;


    /* Inside auto layout */
    flex: none !important;
    order: 1 !important;
    flex-grow: 0 !important;
}

.MuiButton-containedPrimary {
    /* Button/Primary */

    /* Auto layout */
    display: flex !important;
    align-items: center !important;
    padding: 12px 24px !important;
    gap: 8px !important;
    height: 48px !important;

    /* Orange/400 */
    background: #FA7E48 !important;
    box-shadow: 0 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0 -1px 0 rgba(14, 14, 44, 0.4) !important;
    border-radius: 16px !important;
}

.MuiButton-containedPrimary:hover {
    /* Button/Primary/hover */

    /* Auto layout */
    display: flex !important;
    align-items: center !important;
    padding: 12px 24px !important;
    gap: 8px !important;
    height: 48px !important;

    /* Orange/400 */
    background: #FA7E48 !important;
    opacity: 0.75 !important;
    box-shadow: 0 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0 -1px 0 rgba(14, 14, 44, 0.4) !important;
    border-radius: 16px !important;
}

.MuiButton-containedPrimary:active {
    /* Button/Primary/pressed */

    /* Auto layout */
    display: flex !important;
    align-items: center !important;
    padding: 12px 24px !important;
    gap: 8px !important;
    height: 48px !important;

    /* Orange/400 */
    background: #FA7E48 !important;
    opacity: 0.5 !important;
    box-shadow: 0 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0 -1px 0 rgba(14, 14, 44, 0.4) !important;
    border-radius: 16px !important;
}

.MuiButton-containedSecondary {
    /* Button/Tertiary */

    /* Auto layout */
    display: flex !important;
    align-items: center !important;
    padding: 12px 24px !important;
    gap: 8px !important;
    height: 48px !important;

    /* White */
    background: #FFFFFF !important;
    /* Orange/400 */
    border: 1px solid #FA7E48 !important;
    border-radius: 16px !important;
    box-shadow: none !important;
}


.MuiButton-containedSecondary span {
    /* Label */

    /* Button */
    font-family: 'Roboto', sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    /* identical to box height */
    display: flex !important;
    align-items: center !important;
    letter-spacing: 0.04em !important;

    /* Orange/400 */
    color: #FA7E48 !important;
}

.MuiButton-containedSecondary:hover {
    /* Button/Tertiary/hover */

    box-sizing: border-box !important;

    /* Auto layout */
    display: flex !important;
    align-items: center !important;
    padding: 12px 24px !important;
    gap: 8px !important;
    height: 48px !important;

    background: #FFE7DC !important;
    border: 1px solid #FA7E48 !important;
    border-radius: 16px !important;

}

.MuiButton-containedSecondary:active {
    /* Button/Tertiary/pressed */

    box-sizing: border-box !important;

    /* Auto layout */
    display: flex !important;
    align-items: center !important;
    padding: 12px 24px !important;
    gap: 8px !important;
    height: 48px !important;

    background: #FFD5C2 !important;
    border: 1px solid #FA7E48 !important;
    border-radius: 16px !important;
}

.Mui-disabled {
    /* Button/Primary/disabled */

    /* grey */
    background: #666666 !important;
    opacity: 0.5 !important;
    box-shadow: 0 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0 -1px 0 rgba(14, 14, 44, 0.4) !important;
    border: none !important;

    color: #FFFFFF !important;
}

.header-manual {
    font-family: dancing-script-bold, sans-serif;
    font-size: 80px;
    letter-spacing: 0;
    color: #FA7E48;
}

.howdoesitwork-bg {
    background-image: url('../public/images/landingpage/bg_shape.svg');
    position: absolute;
    left: 0;
    top: 25px;
    height: 300px;
    width: 100%;
}

.ouroffering-bg {
    background-image: url('../public/images/landingpage/bg_shape.svg');
    position: absolute;
    left: 0;
    top: 25px;
    height: 300px;
    width: 100%;
}

.speakaboutus-bg {
    background-image: url('../public/images/landingpage/bg_shape.svg');
    position: absolute;
    left: 0;
    top: 25px;
    height: 300px;
    width: 100%;
}

.ourpostcards-bg {
    background-image: url('../public/images/landingpage/bg_shape.svg');
    position: absolute;
    left: 0;
    top: 25px;
    height: 300px;
    width: 100%;
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    scale: 0.9;
}

.swiper-slide-shadow-coverflow {
    background-image: none !important;
}

.swiper-pagination-bullet-active {
    background-color: #FA7E48;
}

.review-card {
    box-shadow: 0 0 3.5px rgba(0, 0, 0, 0.25) !important;
}

.review-postcard {
    max-width: calc(450px - 16px);
    max-height: calc(300px - 16px);
    width: calc(100% - 16px);
    aspect-ratio: 15/10;
    object-fit: cover;
}

.MuiSwitch-switchBase {
    padding: 0 !important;
    margin: 2px !important;
    transition-duration: 300ms !important;
}

.Mui-checked {
    transform: translateX(16px) !important;
    color: #fff !important;
}

.MuiSwitch-track {
    border-radius: 14px !important;
    background-color: #ECF1F4;
    opacity: 1 !important;
}

.Mui-checked .MuiSwitch-track {
    opacity: 1 !important;
    border: 0 !important;
    background-color: #FA7E48 !important;
}

.Mui-disabled .MuiSwitch-track {
    opacity: 0.5 !important;
}

.Mui-focusVisible .MuiSwitch-thumb {
     color: #FA7E48 !important;
     border: 6px solid #fff !important;
}

.Mui-disabled .MuiSwitch-thumb {
    color: #666 !important;
}

.Mui-disabled .MuiSwitch-track {
    opacity: 0.7 !important;
}

.MuiSwitch-thumb {
    box-sizing: border-box !important;
    width: 22px !important;
    height: 22px !important;
}

.cart-postcard-recap {
    background: #FFFFFF !important;
    /* M_DropShadow */
    box-shadow: 0 0 3.5px rgba(0, 0, 0, 0.25) !important;
    border-radius: 15px !important;
}

.MuiInputBase-root {
    height: 48px !important;
}

.MuiFormLabel-root {
    top: -4px !important;
}

.Mui-disabled.no-bg-on-disabled {
    background-color: transparent !important;
    box-shadow: none !important;
}

.cropper-line {
    background-color: white !important;
}

.cropper-point {
    background-color: white !important;
    border-radius: 50% !important;
    opacity: 1 !important;
    height: 12px !important;
    width: 12px !important;
}

.point-nw {
    opacity: 1;
    transform: translateY(-3px) translateX(-3px);
}

.point-ne {
    opacity: 1;
    transform: translateY(-3px) translateX(3px);
}

.point-sw {
    opacity: 1;
    transform: translateY(3px) translateX(-3px);
}

.point-se {
    opacity: 1;
    transform: translateY(3px) translateX(3px);
}

@media (pointer: coarse) {
    .cropper-point {
        height: 24px !important;
        width: 24px !important;
    }

    .point-nw {
        transform: translateY(-9px) translateX(-9px);
    }

    .point-ne {
        transform: translateY(-9px) translateX(9px);
    }

    .point-sw {
        transform: translateY(9px) translateX(-9px);
    }

    .point-se {
        transform: translateY(9px) translateX(9px);
    }
}

.point-e {
    opacity: 0 !important;
}

.point-w {
    opacity: 0 !important;
}

.point-n {
    opacity: 0 !important;
}

.point-s {
    opacity: 0 !important;
}

.no-focus:hover:is(:disabled,
        [disabled],
        [disabled=true],
        [disabled="true"]) {
    background: #666666 !important;
    opacity: 0.5 !important;
    box-shadow: 0 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0 -1px 0 rgba(14, 14, 44, 0.4) !important;
    border: none !important;

    color: #FFFFFF !important;
}

.message {

}

